import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Spring } from "react-spring/renderprops"
import { Link } from "gatsby"
import { animated, config } from "react-spring"
import parse from "html-react-parser"
import * as styles from "./their-names.module.css"
import { Container } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"
import Arrow from "../components/arrow"
import ClientOnly from "../components/clientOnly"

export default function TheirNames({ data }) {
  const [destination, setDestination] = useState(-1)
  const isDestination = d => {
    return d === destination
  }
  const workers = data.allWpWorker.nodes
  const isMobile = useMediaQuery({
    query: "(max-width: 42rem)",
  })
  return (
    <div>
      <SEO
        title="Their Names | Forever Essential"
        description="Honor the legacies of California healthcare workers who have lost their lives to COVID-19."
        fbHeadline="Their Names | Forever Essential"
        fbDescription="Honoring the memory of healthcare workers lost to COVID-19."
        twShare="As a community, we are remembering healthcare workers who have passed away due to COVID-19. They are #ForeverEssential. Join me in honoring their legacies."
      />
      <ClientOnly>
        <Layout handlerDestination={setDestination}>
          <div className="section theirnames_section">
            <div className="slide">
              <Container>
                <Spring
                  delay={isDestination(0) ? 1500 : 0}
                  to={{
                    opacity: isDestination(0) ? 1 : 0,
                    transform: isDestination(0)
                      ? "translateY(0)"
                      : "translateY(-200px)",
                  }}
                  config={config.molasses}
                >
                  {props => (
                    <animated.div style={props}>
                      <h1
                        style={{
                          textAlign: isMobile ? "center" : "left",
                          fontStyle: "italic",
                        }}
                      >
                        Their Names
                      </h1>
                    </animated.div>
                  )}
                </Spring>
                <Spring
                  delay={isDestination(0) ? 1500 : 0}
                  to={{
                    opacity: isDestination(0) ? 1 : 0,
                    transform: isDestination(0)
                      ? "translateY(0)"
                      : "translateY(20px)",
                  }}
                  config={config.molasses}
                >
                  {props => (
                    <animated.div style={props}>
                      <h2
                        style={{
                          textAlign: isMobile ? "center" : "left",
                        }}
                      >
                        Healthcare workers aren’t just essential to their
                        patients. They are beloved family members, treasured
                        coworkers, and cornerstones of our communities.
                      </h2>
                    </animated.div>
                  )}
                </Spring>
              </Container>
            </div>
            <div className="slide">
              <Container>
                <Spring
                  delay={isDestination(0) ? 1500 : 0}
                  to={{
                    opacity: isDestination(0) ? 1 : 0,
                  }}
                  config={config.molasses}
                >
                  {props => (
                    <animated.div style={props}>
                      <h2
                        style={{
                          textAlign: isMobile ? "center" : "left",
                        }}
                      >
                        Search below to learn about the lives and legacies of
                        healthcare workers we have lost to COVID-19.
                      </h2>
                    </animated.div>
                  )}
                </Spring>
                <Spring
                  delay={isDestination(0) ? 1500 : 0}
                  to={{
                    opacity: isDestination(0) ? 1 : 0,
                    transform: isDestination(0)
                      ? "translateY(0)"
                      : "translateY(-70px)",
                  }}
                  config={config.molasses}
                >
                  {props => (
                    <div
                      style={{
                        ...props,
                        textAlign: "center",
                        marginTop: "15%",
                      }}
                    >
                      <h1>
                        <Arrow
                          direction="down"
                          width="50px"
                          onClick={e => window.fullpage_api.moveSectionDown()}
                        />
                      </h1>
                    </div>
                  )}
                </Spring>
              </Container>
            </div>
          </div>
          <div className="section theirnames_section fp-auto-height">
            <Container>
              <Spring
                delay={isDestination(1) ? 2800 : 0}
                to={{
                  opacity: isDestination(1) ? 1 : 0,
                }}
                config={config.molasses}
              >
                {props => (
                  <animated.div style={props}>
                    <h2
                      style={{
                        textAlign: "center",
                        fontWeight: "600",
                        marginBottom: 0,
                      }}
                    >
                      We are a community.
                    </h2>
                    <h2
                      style={{
                        textAlign: "center",
                        fontWeight: "600",
                        marginTop: 0,
                        marginBottom: 40,
                      }}
                    >
                      We will always remember:
                    </h2>
                  </animated.div>
                )}
              </Spring>
              <Spring
                delay={isDestination(1) ? 3000 : 0}
                to={{
                  opacity: isDestination(1) ? 1 : 0,
                  transform: isDestination(1)
                    ? "translateY(0)"
                    : "translateY(300px)",
                }}
                config={config.molasses}
              >
                {props => (
                  <animated.div style={{ ...props }}>
                    <Container>
                      <p
                        style={{
                          textAlign: isMobile ? "center" : "left",
                          lineHeight: 2,
                        }}
                      >
                        {workers.map(worker => {
                          const title = worker.title
                          const city = worker.workerData.city
                          return (
                            <span
                              key={worker.uri}
                              className={styles.memorialWallText}
                            >
                              <Link to={worker.uri} itemProp="url">
                                <span itemProp="headline">
                                  {" "}
                                  {`${parse(title)}, ${city}`}{" "}
                                </span>
                              </Link>
                              |
                            </span>
                          )
                        })}
                      </p>
                    </Container>
                  </animated.div>
                )}
              </Spring>
            </Container>
          </div>
        </Layout>
      </ClientOnly>
    </div>
  )
}

export const pageQuery = graphql`
  query TheirNamesQuery {
    allWpWorker(sort: { fields: [title], order: DESC }) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        workerData {
          city
        }
      }
    }
  }
`
